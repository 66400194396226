import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase"; 
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Paper } from "@mui/material";
import "./Login.scss"; 

function Login() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    signInWithEmailAndPassword(auth, email, password)
      .then((data) => {
        console.log(data, "authData");
        navigate("/admin-home");
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
        alert(error.message);
      });
  };

  const handleReset = () => {
    navigate("/reset-password");
  };

  return (
    <div className="login-container">
      <Paper className="login-box" elevation={3}>
        <Typography variant="h5" component="h1" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Login
          </Button>
          <p
            onClick={handleReset}
            style={{
              cursor: "pointer",
              color: "#3f51b5",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Forgot Password?
          </p>
        </form>
      </Paper>
    </div>
  );
}

export default Login;