import React from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { TextField, Button, Typography, Paper } from "@mui/material";
import './ResetPassword.scss'; // Import your CSS file

function ResetPassword() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    try {
      await sendPasswordResetEmail(auth, email);
      alert("Check your email inbox for the password reset link.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Error sending password reset email: " + error.message);
    }
  };

  return (
    <div className="reset-password-container">
      <Paper className="reset-password-box" elevation={3}>
        <Typography variant="h5" component="h1" gutterBottom>
          Forgot Your Password?
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Reset Password
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default ResetPassword;
