import React from "react";
import './NavbarLanding.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import logoLptm from "../../Assets/foto/logo-lptm.png"; // Pastikan jalur ini benar

function NavbarLanding() {
  return (
    <Navbar expand="lg" className="navbar" variant="light">
      <Container fluid>
        <Navbar.Brand className="d-flex align-items-center">
          <Link to="/" className="text-decoration-none brand-link">
            <img src={logoLptm} alt="logo" className="logo" />
          </Link>
          <div className="judul-landing-page">Sertifikat Peserta</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto nav-links">
            <Nav.Link as={Link} to="/" className="nav-link-item">Beranda</Nav.Link>
            <Nav.Link as={Link} to="/program" className="nav-link-item">Pelatihan</Nav.Link>
            <Nav.Link as={Link} to="/login" className="nav-link-item">Masuk</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarLanding;



// import React from "react";
// import './NavbarLanding.scss';
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import Container from 'react-bootstrap/Container';
// import { Link } from "react-router-dom";
// import logoLptm from "../../Assets/foto/logoLptm2.png"; // Pastikan jalur ini benar

// function NavbarLanding() {
//   return (
//     <Navbar expand="lg" className="navbar" variant="light">
//       <Container>
//         <Navbar.Brand className="d-flex align-items-center">
//           <Link to="/" className="text-decoration-none brand-link">
//             <img src={logoLptm} alt="logo" className="logo" />
//           </Link>
//           <div className="judul-landing-page">Laman Sertifikasi</div>
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="ms-auto nav-links">
//             <Nav.Link as={Link} to="/" className="nav-link-item">Beranda</Nav.Link>
//             <Nav.Link as={Link} to="/program" className="nav-link-item">Pelatihan</Nav.Link>
//             <Nav.Link as={Link} to="/login" className="nav-link-item">Masuk</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default NavbarLanding;
