import React from 'react';
import SidebarAdmin from '../../components/sidebarAdmin/SidebarAdmin';
import './AdminHome.scss';
import logoLptm from '../../Assets/foto/logo-lptm.png';
import { Outlet } from 'react-router-dom';
function AdminHome() {
  return (
    <div className="adminHome">
      <SidebarAdmin />
      <div className="mainContent">
        <h1>Selamat Datang di Admin Dashboard</h1>
        <p>Dashboard untuk menambahkan partisipan yang tersertifikasi LPTM</p>
        {/* <Outlet /> untuk menunjukkan di mana konten utama akan dirender. */}
        <Outlet />
      </div>
      {/* <UserData/> */}
    </div>
  );
}

export default AdminHome;
