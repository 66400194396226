// Komponen ini akan memeriksa status autentikasi dan mengarahkan 
// pengguna ke halaman login jika belum autentikasi.
import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";


const ProtectedRoute = ({ element, ...rest }) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>; // Atau spinner loading
  }

  if (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }

  return user ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
