import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Box,
  Grid,
  TextField,
  IconButton,
  Avatar,
  InputAdornment,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import QRCode from "react-qr-code";
import "./UserData.scss";
import { useNavigate } from "react-router-dom";
import DownloadableQRCode from "./DownloadableQRCode.jsx"

const UserData = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchUsers = async () => {
    const usersCollection = collection(db, "participants");
    const usersSnapshot = await getDocs(usersCollection);
    const usersList = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersList);
    setFilteredUsers(usersList);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleOpenView = (user) => {
    setSelectedUser(user);
    setViewOpen(true);
  };

  const handleCloseView = () => {
    setViewOpen(false);
    setSelectedUser(null);
  };

  const handleOpenEdit = (user) => {
    setSelectedUser(user);
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async (userId) => {
    const userDoc = doc(db, "participants", userId);
    await deleteDoc(userDoc);
    fetchUsers(); // Refresh the list after deletion
  };

  const handleUpdate = async () => {
    const userDoc = doc(db, "participants", selectedUser.id);
    await updateDoc(userDoc, selectedUser);
    handleCloseEdit();
    fetchUsers(); // Refresh the list after update
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setFilteredUsers(
      users.filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    try {
      const date = timestamp.toDate();
      return date.toLocaleDateString();
    } catch (error) {
      console.error("Error converting timestamp:", error);
      return "Invalid date";
    }
  };

  const handleAddUserClick = () => {
    navigate("/admin-home/new"); // Navigate to /new
  };

  

  return (
    <div className="user-data">
      {/* Add User Button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "16px" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ width: "200px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddUserClick}
          style={{ marginLeft: "8px" }} // Sesuaikan nilai margin di sini
        >
          Add User
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Photo</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Certificate Number</TableCell>
              <TableCell>Birth Place & Date</TableCell>
              <TableCell>Training Type</TableCell>
              <TableCell>Sub-Training Type</TableCell>
              <TableCell>Training Date</TableCell>
              <TableCell>Finish Training Date</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Predicate</TableCell>
              <TableCell>Graduation Date</TableCell>
              <TableCell>Certificate Signing Date</TableCell>
              <TableCell>Director Name</TableCell>
              <TableCell>QRCode</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Avatar src={user.image} alt={user.name} />
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.certificateNumber}</TableCell>
                <TableCell>{user.birthPlaceDate}</TableCell>
                <TableCell>{user.trainingType}</TableCell>
                <TableCell className={user.trainingSubType ? "" : "empty-cell"}>
                  {user.trainingSubType || "N/A"}
                </TableCell>

                <TableCell>{formatDate(user.trainingDate)}</TableCell>
                <TableCell>{formatDate(user.finishTrainingDate)}</TableCell>
                <TableCell>{user.duration}</TableCell>
                <TableCell>{user.level}</TableCell>
                <TableCell>{user.predicate}</TableCell>
                <TableCell>{formatDate(user.graduationDate)}</TableCell>
                <TableCell>{user.signDate}</TableCell>
                <TableCell>{user.directorName}</TableCell>
                <TableCell>
                <DownloadableQRCode value={`https://sertifikat-peserta.lptm.co.id/single/user/${user.id}`}
                      userName={user.name} 
  />
</TableCell>

                <TableCell>
                  <IconButton onClick={() => handleOpenView(user)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{user.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal View */}
      <Modal open={viewOpen} onClose={handleCloseView}>
        <div className="modal-content-userdata">
          <h2>User Details</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                fullWidth
                value={selectedUser?.name}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Certificate Number"
                fullWidth
                value={selectedUser?.certificateNumber}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Birth Place & Date"
                fullWidth
                value={selectedUser?.birthPlaceDate}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Training Type"
                fullWidth
                value={selectedUser?.trainingType}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Sub-Training Type"
                fullWidth
                value={selectedUser?.trainingSubType}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Training Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.trainingDate
                    ? new Date(selectedUser.trainingDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Finish Training Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.finishTrainingDate
                    ? new Date(selectedUser.finishTrainingDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Duration (session)"
                fullWidth
                value={selectedUser?.duration}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Level"
                fullWidth
                value={selectedUser?.level}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Predicate"
                fullWidth
                value={selectedUser?.predicate}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Graduation Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.graduationDate
                    ? new Date(selectedUser.graduationDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Certificate Signing Date"
                fullWidth
                value={selectedUser?.signDate}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Director Name"
                fullWidth
                value={selectedUser?.directorName}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              {selectedUser?.image ? (
                <img
                  src={selectedUser.image}
                  alt={selectedUser.name}
                  className="user-photo"
                />
              ) : (
                <span>No Photo</span>
              )}
            </Grid>
          </Grid>
        </div>
      </Modal>

      {/* Modal Edit */}
      <Modal open={editOpen} onClose={handleCloseEdit}>
        <div className="modal-content-userdata">
          <h2>Edit User</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                fullWidth
                value={selectedUser?.name || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Certificate Number"
                fullWidth
                value={selectedUser?.certificateNumber || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({ ...prev, certificateNumber: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Birth Place & Date"
                fullWidth
                value={selectedUser?.birthPlaceDate || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    birthPlaceDate: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Training Type"
                fullWidth
                value={selectedUser?.trainingType || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    trainingType: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Sub-Training Type"
                fullWidth
                value={selectedUser?.trainingSubType || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    trainingSubType: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Training Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.trainingDate
                    ? new Date(selectedUser.trainingDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    trainingDate: new Date(e.target.value),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Finish Training Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.finishTrainingDate
                    ? new Date(selectedUser.finishTrainingDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    trainingDate: new Date(e.target.value),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Duration (session)"
                fullWidth
                value={selectedUser?.duration || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    duration: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Level"
                fullWidth
                value={selectedUser?.level || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    level: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Predicate"
                fullWidth
                value={selectedUser?.predicate || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    level: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Graduation Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  selectedUser?.graduationDate
                    ? new Date(selectedUser.graduationDate.seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    graduationDate: new Date(e.target.value),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Certificate Signing Date"
                fullWidth
                value={selectedUser?.signDate || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    signDate: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Director Name"
                fullWidth
                value={selectedUser?.directorName || ""}
                onChange={(e) =>
                  setSelectedUser((prev) => ({
                    ...prev,
                    directorName: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              {selectedUser?.image ? (
                <img
                  src={selectedUser.image}
                  alt={selectedUser.name}
                  className="user-photo"
                />
              ) : (
                <span>No Photo</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={handleUpdate}
                  style={{ textTransform: "none" }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default UserData;
