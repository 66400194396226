import React from 'react'
import NavbarLanding from '../../components/navbarLanding/NavbarLanding'
import LandingPage from '../../components/landingPage/LandingPage'
import Footer from '../../components/footer/Footer'
import "./Program.scss"
import img from '../../Assets/foto/images-11.png';
import FirstHero from '../../components/firstHero/FirstHero'
import InspoProgram from '../../components/inspoProgram/InspoProgram'
import ListTraining from '../../components/listTraining/ListTraining'
const Program = () => {
  // Data untuk InspoProgram
  const inspoItem = {
    imageUrl: require("../../Assets/foto/images-17.jpeg"),
    title: "Tentang Kami",
    text: "Lembaga Profesi Teknik dan Manajemen (LPTM) menyelenggarakan pelatihan (kursus) bidang profesi teknik maupun Manajemen bagi siapapun yang ingin memiliki keterampilan kerja bersertifikasi agar siap memasuki dunia kerja."
  };


  return (
    <div className='program-page'>
      <NavbarLanding/>
      <div className="program-header">
        <h1>Pelatihan</h1>
        <h2>Raihlah peluang untuk cepat kerja bersama LPTM!</h2>
      </div>
      
      {/* Add padding to ListTraining */}
      <div className="list-training-container" style={{ padding: '20px' }}>
        <ListTraining/>
      </div>
      
      {/* Add padding to Footer */}
      <div className="footer-container">
        <Footer/>
      </div>
    </div>
  )
}

export default Program
