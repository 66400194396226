import React from 'react'
import "./LandingPage.scss"

function LandingPage({name, title, cover}) {
  return (
    <div>
         <div className='first-hero-landingpage'>
            <div className='container-landingpage'>
            <span>{name}</span>
            <h1>{title}</h1>
            </div>
            <img src={cover} alt='hero-cover' />
        </div>
      
    </div>
  )
}

export default LandingPage
