import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";

import excavatorIcon from "../../Assets/foto/icons/excafator.png";
import carIcon from "../../Assets/foto/icons/car.png";
import heavyMachineryIcon from "../../Assets/foto/icons/alat berat.png";
import motorbikeIcon from "../../Assets/foto/icons/motor.png";
import electricWeldingIcon from "../../Assets/foto/icons/electric.png";
import shipWeldingIcon from "../../Assets/foto/icons/ship.png";
import forkliftIcon from "../../Assets/foto/icons/forklift.png";
import landSurveyIcon from "../../Assets/foto/icons/land-ruler.png";
import solarPowerIcon from "../../Assets/foto/icons/solar-energy.png";
import fertilizerIcon from "../../Assets/foto/icons/fertilizer.png";
import rainWaterIcon from "../../Assets/foto/icons/rain-water.png";
import dumpTruckIcon from "../../Assets/foto/icons/dump-truck.png";

import "./ListTraining.scss";

const ListTraining = () => {
  const items = [
    {
      icon: excavatorIcon,
      text: "Pelatihan Operator Excavator",
      description:
        "Pelatihan Operator Excavator merupakan kelas yang paling banyak diminati. Terdiri dari beberapa pilihan kelas Seperti Kelas Andalan (OPERATOR + MEKANIK) Serta Kelas Khusus dengan durasi yang berbeda-beda sesuai dengan kebutuhan peserta.",
      tooltip: "Segera Daftar",
    },
    {
      icon: carIcon,
      text: "Pelatihan Mekanik Otomotif Mobil",
      description:
        "Dalam waktu 4 minggu, peserta dapat memahami beberapa alat seperti Engine Trainer, Cooling Trainer, Electrical Trainer, Chasis, dan Tune Up.",
      tooltip: "Segera Daftar",
    },
    {
      icon: heavyMachineryIcon,
      text: "Pelatihan Mekanik Alat Berat",
      description:
        "Dalam 4 minggu peserta akan menguasai dari bongkar pasang hingga maintenance pada alat berat.",
      tooltip: "Segera Daftar",
    },
    {
      icon: motorbikeIcon,
      text: "Pelatihan Mekanik Otomotif Motor",
      description:
        "Dalam waktu 4 minggu, peserta dapat memahami beberapa alat seperti Engine Trainer, Sistem Kelistrikan, Electrical Trainer, Chasis, dan Tune Up.",
      tooltip: "Segera Daftar",
    },
    {
      icon: electricWeldingIcon,
      text: "Pelatihan Teknisi Las Listrik",
      description:
        "Dalam waktu 4 minggu, peserta dapat memahami bagaimana cara pengelasan Down Head, Horizontal, Vertical, dan Over Head.",
      tooltip: "Segera Daftar",
    },
    {
      icon: shipWeldingIcon,
      text: "Pelatihan Teknisi Las Kapal",
      description:
        "Dalam 4 minggu peserta akan menguasai pengelasan kapal dari Down Head, Horizontal, Vertical, dan Over Head.",
      tooltip: "Segera Daftar",
    },
    {
      icon: forkliftIcon,
      text: "Pelatihan Operator Forklift",
      description:
        " Dalam waktu 4 minggu, peserta dapat mempersiapkan dan mengoperasikan Forklift serta mengendalikan beban dengan jenis alat Komatsu 2.5.",
      tooltip: "Segera Daftar",
    },
    {
      icon: landSurveyIcon,
      text: "Pelatihan Juru Ukur Tanah",
      description:
        "Dalam 4 Minggu peserta akan menguasai dari pengenalan pesawat ukur tanah hingga penggambaran poligon dengan alat Theodolite & Waterpass dan Total Station.",
      tooltip: "Segera Daftar",
    },
    {
      icon: solarPowerIcon,
      text: "Pelatihan Listrik Tenaga Surya",
      description:
        "Pelatihan mengenai listrik tenaga surya yang dilakukan selama sehari penuh.",
      tooltip: "Segera Daftar",
    },
    {
      icon: fertilizerIcon,
      text: "Pelatihan Teknologi Tepat Guna Membuat Pupuk Cair",
      description:
        "Pelatihan Teknologi Tepat Guna Membuat Pupuk Cair dilakukan selama sehari penuh.",
      tooltip: "Segera Daftar",
    },
    {
      icon: rainWaterIcon,
      text: "Pelatihan Teknik Memanen Air Hujan",
      description:
        "Pemanfaatan Lubang Resapan Biopori untuk mengalirkan air hujan kedalam tanah. Selain bertujuan untuk mencegah genangan dan banjir, menabung air hujan digunakan pada saat musim kemarau.",
      tooltip: "Segera Daftar",
    },
    { 
      icon: dumpTruckIcon,
      text: "Pelatihan Operator Dump Truck",
      description:
        "Dalam waktu 4 minggu, peserta dibekali Teori pengoperasian, Teori pengenalan sistem mekanik alat berat dan Praktek pengoperasian Dump Truck 6 roda.",
      tooltip: "Segera Daftar",
    },
  ];

  return (
    <Box className="icon-text-list">
      {items.map((item, index) => (
        <Tooltip key={index} title={item.tooltip} arrow>
          <Box className="icon-text-item">
            <Box className="icon-text-content">
              <Box className="icon-box">
                <img src={item.icon} alt={item.text} className="icon" />
              </Box>
              <Typography variant="h5" mt={1} className="icon-text-title">
                {item.text}
              </Typography>
            </Box>
            <Box className="icon-text-description">
              <Typography variant="body1" className="icon-text-description-text">
                {item.description}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export default ListTraining;
