import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SourceIcon from '@mui/icons-material/Source';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './SidebarAdmin.scss';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

const SidebarAdmin = () => {
  const [isOpen, setIsOpen] = useState(true); 
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 769);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const updateMedia = () => {
    setIsLargeScreen(window.innerWidth >= 769);
    if (window.innerWidth >= 769) {
      setIsOpen(true); 
    } else {
      setIsOpen(false);
    }
  };

  const navigate = useNavigate();
  const handleClick = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        navigate('/');
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div>
      <button className="menu-btn" onClick={toggleSidebar}>
        <MenuIcon />
      </button>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="top">
          <div className="logo">
            <Link to="/admin-home" onClick={isLargeScreen ? undefined : toggleSidebar} style={{ textDecoration: 'none' }}>
              <span style={{ color: 'white' }}>Admin Dashboard</span>
            </Link>
          </div>
          <button className="close-btn-sidebar" onClick={toggleSidebar}>
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
        <hr />
        <div className="center-sidebar">
          <ul>
            <li>
              <Link to="/admin-home/new" onClick={isLargeScreen ? undefined : toggleSidebar} style={{ textDecoration: 'none', color: '#ffffff' }}>
                <GroupAddIcon style={{ marginRight: '8px' }} />
                <span>Add Users</span>
              </Link>
            </li>
            <li>
              <Link to="/admin-home/view" onClick={isLargeScreen ? undefined : toggleSidebar} style={{ textDecoration: 'none', color: '#ffffff' }}>
                <SourceIcon style={{ marginRight: '8px' }} />
                <span>View Data</span>
              </Link>
            </li>
            <li>
              <button className="logout-btn" onClick={handleClick}>
                <ExitToAppIcon className="logout-icon" />
                <span>Log Out</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
