import React, { useState, useEffect } from "react";
import "./UserInput.scss";
import {
  TextField,
  Button,
  Grid,
  Container,
  Alert,
  Typography,
  Input,
  MenuItem,
} from "@mui/material";
import { db, storage } from "../../firebase";
import { collection, addDoc, getDocs, Timestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const UserInput = () => {
  const [formData, setFormData] = useState({
    name: "",
    certificateNumber:"",
    birthPlaceDate: "",
    trainingType: "",
    trainingSubType: "", // Add this line
    trainingDate: "",
    finishTrainingDate: "",
    duration: "",
    level: "",
    graduationDate: "",
    predicate: "",
    signDate: "",
    directorName: "",
    image: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [users, setUsers] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const trainingTypes = {
    "Pelatihan Operator Alat Berat Dumptruck": [
      "Dumptruck 10 Roda",
      "Dumptruck 6 Roda",
      "Dumptruck Hemat",
    ],
    "Pelatihan Operator Alat Berat Excavator": ["Reguler", "Lanjutan", "Khusus"],
    "Pelatihan Juru Ukur Tanah": [],
    "Pelatihan Teknik Las": ["Teknik Las Kapal", "Teknik Las Listrik"],
    "Pelatihan Mekanik Otomotif": [
      "Mekanik Otomotif Motor",
      "Mekanik Otomotif Mobil",
    ],
    "Pelatihan Mekanik Alat Berat": [],
    "Pelatihan Operator Alat Berat Forklift": [],
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImagePreview("");
    setFormData((prevData) => ({
      ...prevData,
      image: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = "";

    if (imageFile) {
      const imageRef = ref(storage, `image/${imageFile.name}`);
      try {
        const uploadResult = await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(uploadResult.ref);
      } catch (uploadError) {
        console.error("Error uploading image:", uploadError);
        setAlertMessage("Error uploading image.");
        setAlertSeverity("error");
        return;
      }
    }

    const formattedData = {
      ...formData,
      image: imageUrl || formData.image,
      trainingDate: Timestamp.fromDate(new Date(formData.trainingDate)),
      finishTrainingDate: Timestamp.fromDate(
        new Date(formData.finishTrainingDate)
      ),
      graduationDate: Timestamp.fromDate(new Date(formData.graduationDate)),
    };

    try {
      const docRef = await addDoc(
        collection(db, "participants"),
        formattedData
      );
      console.log("Document written with ID:", docRef.id);
      fetchUsers();
      setAlertMessage("User added successfully.");
      setAlertSeverity("success");
    } catch (error) {
      console.error("Error adding document:", error);
      setAlertMessage("Error adding user.");
      setAlertSeverity("error");
    }
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "participants"));
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (e) {
      console.error("Error fetching users: ", e);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <div className="form-container">
        <Typography variant="h4" gutterBottom>
          Tambahkan Peserta
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label="Name"
                fullWidth
                required
                value={formData.name}
                onChange={handleChange}
              />  
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="certificateNumber"
                label="Certificate Number"
                fullWidth
                required
                value={formData.certificateNumber}
                onChange={handleChange}
              />  
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="birthPlaceDate"
                label="Birth Place & Date"
                fullWidth
                required
                value={formData.birthPlaceDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="trainingType"
                label="Training Type"
                fullWidth
                required
                select
                value={formData.trainingType}
                onChange={handleChange}
                helperText="Please select the type of training"
              >
                {Object.keys(trainingTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              {formData.trainingType &&
                trainingTypes[formData.trainingType].length > 0 && (
                  <TextField
                    name="trainingSubType"
                    label="Sub Training Type"
                    fullWidth
                    required
                    select
                    value={formData.trainingSubType}
                    onChange={handleChange}
                    helperText="Please select the sub-type of training"
                  >
                    {trainingTypes[formData.trainingType].map((subType) => (
                      <MenuItem key={subType} value={subType}>
                        {subType}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="trainingDate"
                label="Training Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={formData.trainingDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="finishTrainingDate"
                label="Finish Training Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={formData.finishTrainingDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="duration"
                label="Duration (session)"
                fullWidth
                required
                value={formData.duration}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="level"
                label="Level"
                fullWidth
                required
                select
                value={formData.level}
                onChange={handleChange}
              > <MenuItem value="Lanjutan">Lanjutan</MenuItem>
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Menengah">Menengah</MenuItem>
                <MenuItem value="Praktik Kerja Lapangan">Praktik Kerja Lapangan</MenuItem>
                <MenuItem value="Magang">Magang</MenuItem>

              
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="graduationDate"
                label="Graduation Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={formData.graduationDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="predicate"
                label="Predicate"
                fullWidth
                value={formData.predicate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}> 
              <TextField
                name="signDate"
                label="Certificate Signing Date"
                fullWidth
                required
                value={formData.signDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="directorName"
                label="Director's Name"
                fullWidth
                value={formData.directorName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                  <Button onClick={handleRemoveImage}>Remove Image</Button>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {alertMessage && (
          <Alert severity={alertSeverity} style={{ marginTop: "20px" }}>
            {alertMessage}
          </Alert>
        )}
      </div>
    </Container>
  );
};

export default UserInput;
