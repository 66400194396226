import React, { useContext } from 'react';
import { UserContext } from './UserContext'; // Sesuaikan path sesuai struktur folder
import './Home.scss';
import LandingPage from '../../components/landingPage/LandingPage';
import img from '../../Assets/foto/excavator_2.jpg';
import NavbarLanding from '../../components/navbarLanding/NavbarLanding';
import UserDetailPage from '../../components/UserDetails/UserDetailPage';
import Footer from "../../components/footer/Footer";
import InspoProgram from '../../components/inspoProgram/InspoProgram';
import CarouselPict from '../../components/carouselPict/CarouselPict';
import image1 from "../../Assets/foto/images-16.jpeg"
import image2 from "../../Assets/foto/mekanik.jpg"
import image3 from "../../Assets/foto/forklift.jpg"


//tesssss
const Home = () => {
  const { user } = useContext(UserContext);

  // Debugging: Periksa nilai user di konsol
  console.log('User Context:', user);

  // Data untuk InspoProgram
  const inspoItem = {
    imageUrl: require("../../Assets/foto/images-19.jpeg"),
    title: "Lembaga Profesi Teknik dan Manajemen",
    text: "Komitmen kami adalah menjadi lembaga pelatihan yang bermanfaat sebanyak-banyaknya bagi generasi penerus bangsa dengan mengedepankan mutu dan biaya pelatihan yang terjangkau bagi seluruh lapisan masyarakat."
  };

  const imagesCarousel = [image1, image2, image3];

  return (
    <div className='home'>
      <NavbarLanding />
      <LandingPage
        name='Lembaga Profesi Teknik dan Manajemen'
        title='Tidak hanya berkualitas, LPTM tentu memberikan yang terbaik demi terwujudnya lulusan yang unggul.'
        cover={img}
      />
      {user && <UserDetailPage />} {/* Tampilkan detail pengguna jika ada */}
      <Footer />
    </div>
  );
}

export default Home;
