import React from "react";
import { Button } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";

const DownloadableQRCode = ({ value, userName }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById(`qr-${value}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${userName || 'QRCode'}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (!value) {
    return <div>Error: QR tidak ditemukan</div>;
  }

  return (
    <div>
      <QRCodeCanvas id={`qr-${value}`} value={value} size={100} />
      <Button onClick={downloadQRCode}>Download</Button>
    </div>
  );
};

export default DownloadableQRCode;
