import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "./UserDetailPage.scss";
import userImage from "../../Assets/foto/images-6.jpg";

const UserDetailPage = ({ userId }) => {
  const [user, setUser] = useState(null);

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "-";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("en-US");
  };

  // Menambahkan useEffect untuk memanggil fetchUser
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const docRef = doc(db, "participants", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          console.log("Fetched user data:", userData);
          setUser({
            ...userData,
            trainingDate: formatDate(userData.trainingDate),
            finishTrainingDate: formatDate(userData.finishTrainingDate),
            graduationDate: formatDate(userData.graduationDate),
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [userId]); // Menggunakan userId sebagai dependensi

  if (!user) return <div>Loading...</div>;


  return (
    <div className="container-userdetail">
      <div className="paper-detail">
        <div className="user-image">
          <img src={user.image || userImage} alt="User" className="user-image" />
        </div>

        <div className="user-info">
          <div className="user-info-item">
            <div className="user-info-label">Nama:</div>
            <span className= "user-info-translation"><i>Name</i></span>
            <div className="user-info-value">{user.name || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">No. Sertifikat:</div>
            <span className= "user-info-translation"><i>Certificate of Training</i></span>
            <div className="user-info-value">{user.certificateNumber || "-"}</div> 
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Tempat Tanggal Lahir:</div>
            <span className= "user-info-translation"><i>Place and DoB</i></span>
            <div className="user-info-value">{user.birthPlaceDate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Jenis Pelatihan:</div>
            <span className= "user-info-translation"><i>Training Type</i></span>
            <div className="user-info-value">{user.trainingType || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Jenis Sub-Pelatihan:</div>
            <span className= "user-info-translation"><i>Training Sub-type</i></span>
            <div className="user-info-value">{user.trainingSubType || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Tanggal Mulai Pelatihan:</div>
            <span className= "user-info-translation"><i>Training Date</i></span>
            <div className="user-info-value">{user.trainingDate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Tanggal Selesai Pelatihan:</div>
            <span className= "user-info-translation"><i>Training End Date</i></span>
            <div className="user-info-value">{user.finishTrainingDate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Durasi Pelatihan:</div>
            <span className= "user-info-translation"><i>Training Duration</i></span>
            <div className="user-info-value">{user.duration || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Level:</div>
            <div className="user-info-value">{user.level || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Tanggal Kelulusan Pelatihan:</div>
            <span className= "user-info-translation"><i>Training Graduation Date</i></span>
            <div className="user-info-value">{user.graduationDate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Predikat:</div>
            <span className= "user-info-translation"><i>Predicate</i></span>
            <div className="user-info-value">{user.predicate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Tanggal Tanda Tangan Sertifikat:</div>
            <span className= "user-info-translation"><i>Signature Date</i></span>
            <div className="user-info-value">{user.signDate || "-"}</div>
          </div>
          <div className="user-info-item">
            <div className="user-info-label">Direktur Penandatangan Sertifikat:</div>
            <span className= "user-info-translation"><i>Certificate Signing Director</i></span>
            <div className="user-info-value">{user.directorName || "-"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
