import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-columns">
          <div className="contact-info">
            <h4>HUBUNGI KAMI</h4>
            <p><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp: +62-823-9878-7870</p>
            <p><FontAwesomeIcon icon={faEnvelope} /> Email: adm.lptm@gmail.com</p>
            <p><FontAwesomeIcon icon={faGlobe} /> Website: lptm.co.id</p>
            <p><FontAwesomeIcon icon={faInstagram} /> Instagram: <a href="https://www.instagram.com/lptm_makassar" target="_blank" rel="noopener noreferrer"> @lptm_makassar</a></p>
            <p><FontAwesomeIcon icon={faFacebook} /> Facebook: <a href="https://www.facebook.com/LPTMPelatihanExcavator" target="_blank" rel="noopener noreferrer"> @LPTMPelatihanExcavator</a></p>
          </div>
          <div className="address-info">
            <h4>ALAMAT</h4>
            <p>Kantor: Jl. Batua Raya No. 29B, Makassar, Sulawesi Selatan</p>
            <p>Praktek Pelatihan Alat Berat & Mekanik: Lapangan Awwalul Islam, Jalan Prof. Dr. Ir. Sutami, Kawasan Pergudangan Lantebung, Makassar</p>
          </div>
        </div>
      </div>
      <p>&copy; {new Date().getFullYear()} Lembaga Profesi Teknik dan Manajemen. All rights reserved.</p>
      <p>All icons and training background we are using in this website are from these <a href="https://drive.google.com/file/d/1Lr6TZArOSVy8Zd-3_caYfWehDwEyMBBX/view?usp=sharing" target="_blank" rel="noopener noreferrer"> Authors</a></p>
    </footer>
  );
};

export default Footer;
