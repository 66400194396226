// UserSingle.js
import React from "react";
import { useParams } from 'react-router-dom';
import NavbarLanding from "../../components/navbarLanding/NavbarLanding";
import "./UserSingle.scss";
import UserDetailPage from "../../components/UserDetails/UserDetailPage";
import InspoProgram from "../../components/inspoProgram/InspoProgram";
import Footer from "../../components/footer/Footer";

function UserSingle() {
  const { userId } = useParams(); //ambil user id dari url

  return (
    <div className="userSingle">
      <NavbarLanding />
      <div className="page-header">
        <h1>Sertifikasi</h1>
        <h2>Raihlah peluang untuk cepat kerja bersama LPTM!</h2>
      </div>
      <div className="content">
        {userId ? (
          <UserDetailPage userId={userId} /> // pindah ke UserDetailPage
        ) : (
          <p>No user data available.</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default UserSingle;
