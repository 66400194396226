import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const BarcodeScanner = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleScan = async (result) => {
    if (result) {
      const userId = result.text; // asumsiin result.text berisi userId
      setLoading(true);
      setErrorMessage(''); // Reset error message

      const userData = await fetchUserData(userId);
      if (userData) {
        // Navigasi ke UserDetailPage  setelah mendapatkan data
        navigate(`/single/user/${userId}`);
      } else {
        setErrorMessage('User not found!');
      }
      setLoading(false);
    }
  };

  const handleError = (error) => {
    console.error(error);
    setErrorMessage('Error scanning QR code. Please try again.');
  };

  const fetchUserData = async (userId) => {
    const docRef = doc(db, 'participants', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  };

  return (
    <div>
      <h1>Scan Barcode</h1>
      {loading && <p>Loading user data...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <QrReader
        onResult={(result, error) => {
          if (result) {
            handleScan(result);
          }
          if (error) {
            handleError(error);
          }
        }}
        onError={handleError}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default BarcodeScanner;
