import React from 'react';
import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import UserData from "./pages/userData/UserData";
import UserInput from "./pages/userInput/UserInput";
import AdminHome from "./pages/adminHome/AdminHome";
import ResetPassword from "./pages/login/ResetPassword";
import BarcodeScanner from "./pages/userSingle/BarcodeScanner";
import UserDetailPage from "./components/UserDetails/UserDetailPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./pages/login/ProtectedRoute";
import { UserProvider } from "./pages/home/UserContext";
import UserSingle from "./pages/userSingle/UserSingle";
import Program from "./pages/program/Program";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <UserProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route
              path="admin-home"
              element={<ProtectedRoute element={<AdminHome />} />}
            >
              <Route path="new" element={<UserInput />} />

              <Route path="view" element={<UserData />} />
              <Route path="scan" element={<BarcodeScanner />} />
            </Route>
            <Route path="program" element={<Program />} />
            <Route path="single/user/:userId" element={<UserSingle />} />
            {/* Rute untuk detail pengguna harus di luar rute admin-home */}
            {/* <Route path="user/:userId" element={<UserDetailPage />} /> */}
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </div>
  );
}

export default App;
