import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyChDt3jguENzObcoelPd40Jcq_xxFSfquM",
  authDomain: "lptm-1-cd9f2.firebaseapp.com",
  projectId: "lptm-1-cd9f2",
  storageBucket: "lptm-1-cd9f2.appspot.com",
  messagingSenderId: "315804946506",
  appId: "1:315804946506:web:b39c75c914cf5db4c856b3",
  measurementId: "G-YBPCJ1WTV1"
};

console.log('Firebase Config:', firebaseConfig);

// Firebase configuration
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

// URL from environment variables
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

// Generate barcode link
const generateBarcodeLink = (userId) => {
    return `${baseUrl}/user/${userId}`;
};

// New access component
const BarcodeComponent = ({ userId }) => {
    console.log('User ID:', userId);  // Debugging line
    if (!userId) {
        return <div>User ID untuk ditampilkan pada  barcode.</div>;
    }
    const barcodeLink = generateBarcodeLink(userId);
    return (
        <div>
            <a href={barcodeLink}>
                <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(barcodeLink)}&size=150x150`} alt="QR Code" />
            </a>
        </div>
    );
};

export { auth, db, storage, generateBarcodeLink, BarcodeComponent };

